<template>
   <v-layout align-start>
      <v-snackbar
         v-model="snackbar"
         :color="snackColor"
         :right="true"
         :bottom="true"
         :timeout="5000"
      >
         {{ snackText }}
         <template #action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
               Cerrar
            </v-btn>
         </template>
      </v-snackbar>

      <!-- DIALOG LOADING ESPERA RESPUESTA POPUP -->
      <v-dialog v-model="dialogEsperaValue" persistent width="333">
         <v-card :color="dialogEsperaColor" dark class="pt-5 pb-2">
            <v-card-text style="color: white" class="text-justify">
               <strong>{{ dialogEsperaMessage }}</strong>
               <br /><br />
               {{ dialogEsperaSubMessage }}

               <div
                  v-if="
                     dialogEsperaMessage ==
                     'Esperando respuesta de ventana de pago.'
                  "
                  class="text-center"
               >
                  <a
                     style="
                        color: white;
                        font-weight: bold;
                        font-size: 17px;
                        text-decoration: underline;
                     "
                     @click="refreshPage"
                  >
                     <br />
                     Cancelar transacción
                     <br /><br />
                  </a>
               </div>

               <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
               ></v-progress-linear>
            </v-card-text>
         </v-card>
      </v-dialog>

      <v-flex xs12>
         <v-card elevation="0">
            <v-card-text class="text--primary" style="margin-top: -20px">
               <v-container grid-list-md fluid>
                  <v-layout wrap>
                     <v-flex xs6>
                        <span class="text-h5 font-weight-medium">
                           Procedimientos
                        </span>
                     </v-flex>
                     <v-flex xs12>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Para poder ejecutar estos métodos su equipo debe de
                           tener acceso a la IP
                           <a
                              href="http://172.18.2.173"
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              172.18.2.173
                           </a>
                        </span>
                        <br />
                        <span class="text-subtitle-1">
                           Al ejecutar los procedimientos estos se abrirán en
                           una nueva pestaña. Cuando la pestaña deje de cargar
                           significa que el procedimiento ha terminado de
                           ejecutarse y puede cerrar la pestaña.
                        </span>
                     </v-flex>

                     <v-flex xs12 class="mt-6 d-flex justify-center">
                        <v-card width="550" class="pa-6">
                           <v-form
                              ref="formRemigrar"
                              v-model="validRemigrar"
                              lazy-validation
                           >
                              <v-layout wrap>
                                 <v-flex xs12>
                                    <span
                                       class="font-weight-medium"
                                       style="font-size: 20px"
                                    >
                                       Remigrar talonarios
                                    </span>
                                 </v-flex>
                                 <v-flex xs12>
                                    <span class="text-subtitle-1">
                                       En caso de migrar varios talonarios a la
                                       vez, se debe ingresar los carnets con
                                       guiones, separados por coma y en
                                       mayúsculas.
                                    </span>
                                 </v-flex>
                                 <v-flex xs12>
                                    <v-text-field
                                       v-model="periodoLectivo"
                                       label="Periodo lectivo"
                                       :rules="rulesPeriodo"
                                       color="primary"
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12>
                                    <v-textarea
                                       v-model="listaCarnets"
                                       rows="4"
                                       :rules="rulesCarnets"
                                       outlined
                                       label="Lista carnets"
                                       color="primary"
                                    ></v-textarea>
                                 </v-flex>
                                 <v-flex xs12 class="d-flex justify-end">
                                    <v-btn
                                       :disabled="!validRemigrar"
                                       color="primary"
                                       @click="remigrarBatch"
                                       >Remigrar</v-btn
                                    >
                                 </v-flex>
                              </v-layout>
                           </v-form>
                        </v-card>
                     </v-flex>
                  </v-layout>
               </v-container>
            </v-card-text>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'

export default {
   name: 'Procedimientos',
   directives: {
      mask
   },
   data: () => ({
      // Variables
      carnet: '',
      listaCarnets: '',
      periodoLectivo: '',
      validRemigrar: true,

      rulesPeriodo: [(v) => !!v || 'Ingrese un periodo lectivo'],
      rulesCarnets: [(v) => !!v || 'Ingrese uno o varios carnets'],

      dialogEsperaValue: false,
      dialogEsperaMessage: 'Ejecutando procedimiento..',
      dialogEsperaSubMessage: 'No cierre esta pestaña o el navegador.',
      dialogEsperaColor: 'primary',

      snackColor: 'primary',
      snackText: '',
      snackbar: false
   }),
   computed: {
      ...authComputed
   },
   created() {
      // Validando acceso al componente
      if (this.permController(18, 'ver')) {
         // Do nothing
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      remigrarBatch() {
         if (this.$refs.formRemigrar.validate()) {
            window.open(
               `http://172.18.2.173:8088/api/RemigrarBatch?periodo=${
                  this.periodoLectivo
               }&carnets=${this.listaCarnets.replace(/ +/g, '').trim()}`,
               '_blank'
            )
         }
      }
   }
}
</script>
